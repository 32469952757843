// theme settings
$medium-editor-bgcolor: #242424;
$medium-editor-button-size: 50px;
$medium-editor-border-radius: 5px;

// theme rules
.medium-toolbar-arrow-under:after {
    border-color: $medium-editor-bgcolor transparent transparent transparent;
    top: $medium-editor-button-size;
}

.medium-toolbar-arrow-over:before {
    border-color: transparent transparent $medium-editor-bgcolor transparent;
    top: -8px;
}

.medium-editor-toolbar {
    background-color: $medium-editor-bgcolor;
    background: linear-gradient(to bottom, $medium-editor-bgcolor, rgba($medium-editor-bgcolor, 0.75));
    border: 1px solid #000;
    border-radius: $medium-editor-border-radius;
    box-shadow: 0 0 3px #000;

    li {
        button {
            background-color: $medium-editor-bgcolor;
            background: linear-gradient(to bottom, $medium-editor-bgcolor, rgba($medium-editor-bgcolor, 0.89));
            border: 0;
            border-right: 1px solid #000;
            border-left: 1px solid #333;
            border-left: 1px solid rgba(#fff, .1);
            box-shadow: 0 2px 2px rgba(0,0,0,0.3);
            color: #fff;
            height: $medium-editor-button-size;
            min-width: $medium-editor-button-size;
            transition: background-color .2s ease-in;

            &:hover {
                background-color: #000;
                color: yellow;
            }
        }

        .medium-editor-button-first {
            border-bottom-left-radius: $medium-editor-border-radius;
            border-top-left-radius: $medium-editor-border-radius;
        }

        .medium-editor-button-last {
            border-bottom-right-radius: $medium-editor-border-radius;
            border-top-right-radius: $medium-editor-border-radius;
        }

        .medium-editor-button-active {
            background-color: #000;
            background: linear-gradient(to bottom, $medium-editor-bgcolor, rgba(#000, 0.89));
            color: #fff;
        }
    }
}

.medium-editor-toolbar-form {
    background: $medium-editor-bgcolor;
    border-radius: $medium-editor-border-radius;
    color: #999;

    .medium-editor-toolbar-input {
        background: $medium-editor-bgcolor;
        box-sizing: border-box;
        color: #ccc;
        height: $medium-editor-button-size;
    }

    a {
        color: #fff;
    }
}

.medium-editor-toolbar-anchor-preview {
    background: $medium-editor-bgcolor;
    border-radius: $medium-editor-border-radius;
    color: #fff;
}

.medium-editor-placeholder:after {
    color: #b3b3b1;
}
